<template>
  <div class="business h-100 bg-gray">
    <div class="business-info-box"
         v-if="business.spmc">
      <div class="info">
        <div class="spzt">
          <img :src="`${publickPath}static/img/icons/icon-spzt-${business.spzt}.png`">
        </div>
        <div class="spzp">
          <img v-if="formData.spzp && formData.spzp.length"
               :src="formData.spzp[0]" />
        </div>
        <div class="other">
          <div class="name">
            {{ business.spmc}}
            <!-- <img @click="showBusness"
                 :src="`${publickPath}static/img/icons/icon-change.png`"> -->
          </div>
          <div class="address van-multi-ellipsis--l2">{{ business.address }}</div>
          <div class="tags">
            <div class="tag">{{business.splbName}}</div>
            <div class="tag">{{business.splxName}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="field-box">
        <label>商家介绍</label>
        <div class="content">{{business.spjs}}</div>
      </div>
      <div class="field-box">
        <label>商家电话</label>
        <div class="content">{{business.gddh}}</div>
      </div>
      <div class="field-box nobb">
        <label>商铺照片</label>
      </div>
      <div class="imgList"
           v-if="formData.spzp&&formData.spzp.length">
        <div class="img-item"
             v-for="(img,i) in formData.spzp"
             :key="i">
          <img :src="img">
        </div>
      </div>
    </div>
    <div class="box">
      <div class="field-box nobb">
        <label>营业执照</label>
      </div>
      <div class="imgList"
           v-if="formData.yyzz&&formData.yyzz.length">
        <div class="img-item"
             v-for="(img,i) in formData.yyzz"
             :key="i">
          <img :src="img">
        </div>
      </div>
    </div>
    <!-- 表单 -->
    <van-form style="margin-top:6px;"
              ref="formData"
              class="u-form">
      <van-field readonly
                 clickable
                 label="本地商家"
                 :rules="[{ required: true, message: '请选择本地商家' }]"
                 placeholder="请选择本地商家">
        <template #input>
          <van-radio-group v-model="formData.bdsjFlag"
                           :disabled="disabled"
                           @change="bdsjChange"
                           direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="formData.bdsjFlag==='1'">
        <van-field v-model="formData.community"
                   required
                   readonly
                   label="社区"
                   placeholder="请选择社区"
                   right-icon="arrow"
                   :rules="[{ required: true, message: '请选择社区' }]"
                   @click="toShowPicker('community')"></van-field>
        <div class="van-cell--required van-cell van-field">
          <div class="van-cell__title van-field__label"><span>详细地址</span></div>
          <div class="van-cell__value van-field__value">
            <div class="my">
              <input type="text"
                     readonly
                     @click="toShowDatePicker('yykssj')"
                     :value="formData.yykssj">-
              <input type="text"
                     readonly
                     @click="toShowDatePicker('yyjssj')"
                     :value="formData.yyjssj">
              <input type="text"
                     @click="toShowPicker('yyksrq')"
                     :value="formData.yyksrq">至
              <input type="text"
                     @click="toShowPicker('yyjsrq')"
                     :value="formData.yyjsrq">
            </div>
          </div>
        </div>
        <van-field required
                   readonly
                   clickable
                   label="优惠特价">
          <template #input>
            <van-radio-group v-model="showZk"
                             :disabled="disabled"
                             direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field v-if="showZk==='1'"
                   v-model="formData.zks"
                   required
                   :readonly="disabled"
                   type="number"
                   clickable
                   label="特价详情"
                   placeholder="请输入特价详情"></van-field>
        <van-field v-if="showZk==='1'"
                   :value="zoneIdsName"
                   required
                   readonly
                   clickable
                   label="优惠范围"
                   placeholder="请选择优惠范围"
                   right-icon="arrow"
                   @click="toShowZkPicker"></van-field>
        <van-field required
                   readonly
                   clickable
                   label="配送">
          <template #input>
            <van-radio-group v-model="formData.shsm"
                             :disabled="disabled"
                             direction="horizontal">
              <van-radio name="1">是</van-radio>
              <van-radio name="0">否</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <div class="box"
             style="padding:6px 0">
          <u-button type="primary"
                    size="large"
                    :disabled="disabled||!hasChange"
                    @click="submit">保存</u-button>
        </div>
      </div>
    </van-form>
    <!-- 商品类别 -->
    <div class="box"
         style="margin-top:6px;">
      <div class="field-box nobb">
        <label>商品类型</label>
        <div class="content"></div>
        <div class="button"
             @click="toShowSplx">
          添加
        </div>
      </div>
      <div class="sjlx-box">
        <div class="sjlx-item"
             v-for="(item,i) in bussinessTypes"
             :key="i">
          <div class="img-box">
            <img v-if="item.image"
                 :src="item.image">
          </div>
          <div class="name">{{item.name}}</div>
          <img :src="`${publickPath}static/img/icons/icon-del.png`"
               class="del"
               @click="delBussinessType(item.businessGoodsTypeId)">
        </div>
      </div>
    </div>
    <!-- 时间弹窗 -->
    <van-popup v-model="showDatePicker"
               round
               position="bottom">
      <van-datetime-picker type="time"
                           @cancel="showDatePicker = false"
                           @confirm="dateTimeConfirm"
                           :title="this.pickType==='yykssj'?'营业开始时间':'营业结束时间'"
                           :min-hour="0"
                           :max-hour="23" />
    </van-popup>
    <!-- 弹窗 -->
    <van-popup v-model="showPicker"
               round
               position="bottom">
      <van-picker show-toolbar
                  ref="picker"
                  :title="pickerTitle"
                  :columns="columns[this.pickType]"
                  @confirm="onConfirm"
                  @cancel="showPicker = false" />
    </van-popup>
    <!-- 优惠范围选择 -->
    <van-popup v-model="showZkPicker"
               round
               position="bottom">
      <div class="van-picker">
        <div class="van-picker__toolbar"><button type="button"
                  class="van-picker__cancel"
                  @click="showZkPicker=false">取消</button>
          <div class="van-ellipsis van-picker__title">优惠范围</div><button type="button"
                  class="van-picker__confirm"
                  @click="cofirmZkPicker">确认</button>
        </div>
        <!---->
        <div class="van-picker__columns">
          <van-checkbox-group v-model="formData.zoneIds"
                              ref="checkboxGroup">
            <van-checkbox v-for="(xq,i) in xqList"
                          :key="i"
                          :name="xq.id">{{xq.name}}</van-checkbox>
          </van-checkbox-group>
        </div>
      </div>
    </van-popup>
    <!-- 商品类型弹窗 -->
    <van-popup v-model="showSplx"
               class="splx">
      <div class="header">商品添加</div>
      <div class="content">
        <div class="tag-box"
             v-for="(type,i) in bussinessTypeList"
             :key="i">
          <div class="tag"
               @click="type.active=!type.active"
               :class="{active:type.active}">{{type.name}}</div>
        </div>
      </div>
      <div class="footer">
        <div class="button cancle"
             @click="showSplx=false">取消</div>
        <div class="line"></div>
        <div class="button confirm"
             @click="confirmSplx">确定</div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import api from '@api'
import UButton from "@/components/u-button";
export default {
  components: { UButton },
  data () {
    return {
      publickPath: process.env.BASE_URL,
      id: this.$route.query.id,
      formData: {
        bdsjFlag: null
      },
      formDataCopy: {
        bdsjFlag: null
      },
      business: {
        spzp: []
      },
      showPicker: false,
      showDatePicker: false,
      pickType: "",
      columns: {
        community: [],
        yyksrq: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
        yyjsrq: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
      },
      xqList: [],
      showZk: '0',
      showZkPicker: false,
      bussinessTypes: [],
      bussinessTypeList: [],
      showSplx: false,
    };
  },
  watch: {},
  computed: {
    pickerTitle () {
      let pickerTitle = "";
      switch (this.pickType) {
        case "community":
          pickerTitle = "社区信息";
          break;
      }
      return pickerTitle;
    },
    zoneIdsName () {
      let zoneIdsName = ''
      this.xqList.forEach(xq => {
        this.formData.zoneIds.forEach(zoneId => {
          if (xq.id === zoneId) {
            zoneIdsName += xq.name
            zoneIdsName += ','
          }
        })
      })
      zoneIdsName = zoneIdsName.substring(0, zoneIdsName.length - 1)
      return zoneIdsName
    },
    disabled () {
      return this.formData.status !== 1
    },
    hasChange () {
      return JSON.stringify(this.formData) !== JSON.stringify(this.formDataCopy)
    }
  },
  mounted () {
    this.id = this.$route.query.id
    this.getDetail(true)
    this.getBusinessType()
  },
  methods: {
    bdsjChange (value) {
      if (this.columns.community && this.columns.community.length) {
        this.$nextTick(() => {
          this.submit(false)
        })
      }
    },
    // 获取详情
    getDetail (getInit) {
      api.initBusiness({ id: this.id }).then(res => {
        let data = res.data
        this.business = data
        data.zoneIds = data.businessZonePOList.length ? data.businessZonePOList.map(item => item.zoneId) : []
        if (data.spzp && data.spzp !== '[]') {
          data.spzp = data.spzp.replace(/\[|]/g, '').split(',').map(item => {
            return item.trim()
          })
        } else {
          data.spzp = []
        }
        if (data.yyzz && data.yyzz !== '[]') {
          data.yyzz = data.yyzz.replace(/\[|]/g, '').split(',').map(item => {
            return item.trim()
          })
        } else {
          data.yyzz = []
        }
        this.formData = Object.assign(this.$tools.getJson(data),
          {
            id: this.id,
            bdsjFlag: data.bdsjFlag ? (data.bdsjFlag + '') : '0',
            community: data.community,
            communityCode: data.communityCode,
            yyjsrq: data.yyjsrq || '周日',
            yyjssj: data.yyjssj || '22:30',
            yyksrq: data.yyksrq || '周一',
            yykssj: data.yykssj || '08:30',
            zks: data.zks ? (data.zks + '') : '',
            shsm: data.shsm ? (data.shsm + '') : '0',
            zoneIds: data.zoneIds
          })
        this.formDataCopy = this.$tools.getJson(this.formData)
        this.showZk = data.zks ? '1' : '0'
        if (getInit) {
          // 获取社区信息
          this.getCommunityList(data.streetCode);
          // 获取小区信息
          this.getXqList(data.communityCode)
        }
      })
    },
    // 获取商品类型
    getBusinessType () {
      api.getBusinessType({ businessId: this.id }).then(res => {
        this.bussinessTypes = res.data || []
        this.getBusinessTypeList()
      })
    },
    // 获取商品类型列表
    getBusinessTypeList () {
      api.getBusinessTypeList().then(res => {
        res.data.forEach(item => {
          item.active = false
          this.bussinessTypes.forEach(it => {
            if (it.code === item.code) {
              item.disabled = true
            }
          })
        })
        this.bussinessTypeList = res.data.filter(item => !item.disabled)
      })
    },
    // 获取社区列表
    getCommunityList (townCode) {
      api.getCommunityList(townCode).then((res) => {
        this.columns.community = res.data.map((item) => {
          return {
            value: item.villageCode,
            text: item.villageName,
          };
        });
      });
    },
    // 获取小区列表
    getXqList (communityCode) {
      api.getXqList(communityCode).then(res => {
        this.xqList = res.data
      })
    },
    toShowSplx () {
      if (!this.disabled) {
        this.showSplx = true
      }
    },
    toShowDatePicker (pickType) {
      if (!this.disabled) {
        this.pickType = pickType
        this.showDatePicker = true
      }
    },
    toShowPicker (pickType) {
      if (!this.disabled) {
        this.pickType = pickType
        this.showPicker = true
      }
    },
    toShowZkPicker () {
      if (!this.disabled) {
        this.showZkPicker = true
      }
    },
    // 弹窗确定
    onConfirm (value) {
      if (!value) {
      } else if (value) {
        this.formData[this.pickType] = value.text ? value.text : value
        value.value && (this.formData[this.pickType + "Code"] = value.value);
        if (this.pickType === 'community') {
          this.formData.zoneIds = []
          this.formData.zoneIdsName = ''
          this.getXqList(value.value)
        }
      }
      this.showPicker = false;
    },
    // 时间范围确定
    dateTimeConfirm (value) {
      this.formData[this.pickType] = value;
      this.showDatePicker = false
    },
    // 优惠范围选择
    cofirmZkPicker () {
      this.showZkPicker = false
    },
    // 商品类型选择
    confirmSplx () {
      let goodsTypeIds = this.bussinessTypeList.filter(item => item.active).map(item => item.id)
      api.saveBusinessType({ goodsTypeIds, businessId: this.id }).then(res => {
        this.showSplx = false
        this.getBusinessType()
      })
    },
    // 商店商品类型删除
    delBussinessType (businessGoodsTypeId) {
      this.$dialog.confirm({
        title: '提示',
        message: '删除该类型吗',
      }).then(() => {
        api.delBusinessType({ businessGoodsTypeId }).then(res => {
          this.getBusinessType()
        })
      }).catch(() => { })

    },
    showBusness () {
      this.$router.push({ path: '/my/businessList' })
    },
    submit (toast = true) {
      this.$refs.formData.validate().then(res => {
        let formData = this.$tools.getJson(this.formData)
        delete formData.businessRespList
        delete formData.businessZonePOList
        delete formData.browseCount
        // if (this.showZk === '0') {
        //   delete formData.zks
        //   delete formData.zoneIds
        // }
        formData.oldSpzps = formData.spzp
        formData.oldYyzzs = formData.yyzz
        delete formData.spzp
        delete formData.yyzz
        api.updateBusiness(formData).then(res => {
          if (res.code == "SUCCESS") {
            this.formDataCopy = this.$tools.getJson(this.formData)
            if (toast) {
              this.$toast({
                message: '提交成功',
              })
            }
          }
        })
      })
    }
  },
};
</script>

<style lang="less" scoped>
.business {
  overflow-y: auto;
}
.business-info-box {
  padding: 2rem /* 70/18.75 */ 0.8rem /* 15/18.75 */ 0.32rem /* 6/18.75 */;
  position: relative;
  background: #fff;
  overflow: hidden;
  &::before {
    display: block;
    content: "";
    position: absolute;
    top: -140%;
    left: 50%;
    transform: translate(-50%);
    width: 30rem;
    height: 20rem;
    border-radius: 50%;
    background: linear-gradient(-90deg, #63b2ff, #2b71fd);
  }
  .info {
    position: relative;
    min-height: 6.133333rem /* 115/18.75 */;
    background: #ffffff;
    box-shadow: 0px 3px 12px 0px rgba(4, 0, 0, 0.08);
    border-radius: 0.533333rem /* 10/18.75 */;
    padding: 1.173333rem /* 22/18.75 */ 0.64rem /* 12/18.75 */ 0.746667rem
      /* 14/18.75 */;
    display: flex;
    overflow: hidden;
    .spzt {
      width: 2.666667rem /* 50/18.75 */;
      height: 2.933333rem /* 55/18.75 */;
      position: absolute;
      top: 0;
      right: 0;
      img {
        width: 100%;
      }
    }
    .spzp {
      width: 2.666667rem /* 50/18.75 */;
      img {
        width: 2.666667rem /* 50/18.75 */;
        height: 2.666667rem /* 50/18.75 */;
        // border-radius: 50%;
      }
    }
    .other {
      // width: calc(100% - 3.413333rem /* 64/18.75 */ - 4rem /* 75/18.75 */);
      padding: 0 2.133333rem /* 40/18.75 */ 0 0.533333rem /* 10/18.75 */;
      text-align: left;
      .name {
        font-family: PingFang SC;
        font-weight: 600;
        color: @fcb;
        font-size: 1.066667rem /* 20/18.75 */;
        line-height: 1.066667rem /* 20/18.75 */;
        display: flex;
        align-items: center;
        .ellipsis;
        img {
          width: 1.12rem /* 21/18.75 */;
          height: 1.12rem /* 21/18.75 */;
          margin-left: 0.32rem /* 6/18.75 */;
        }
      }
      .address {
        margin: 0.64rem /* 12/18.75 */ 0 0.533333rem /* 10/18.75 */;
        font-size: 0.853333rem /* 16/18.75 */;
        line-height: 0.853333rem /* 16/18.75 */;
        font-family: PingFang SC;
        font-weight: 400;
        color: #8c8c8c;
      }
      .tags {
        display: flex;
        .tag {
          height: 1.173333rem /* 22/18.75 */;
          line-height: 1.173333rem /* 22/18.75 */;
          background: rgba(43, 113, 253, 0.2);
          border-radius: 0.106667rem /* 2/18.75 */;
          font-size: 0.746667rem /* 14/18.75 */;
          font-family: PingFang SC;
          font-weight: 400;
          color: #2b71fd;
          padding: 0 0.373333rem /* 7/18.75 */;
          display: inline-block;
          margin-right: 0.373333rem /* 7/18.75 */;
        }
      }
    }
  }
}
.box {
  padding: 0 1.066667rem /* 20/18.75 */;
}
.field-box {
  height: 60px;
  display: flex;
  align-items: center;
  font-size: 0.8rem /* 15/18.75 */;
  font-family: PingFang SC;
  font-weight: 400;
  color: @fcb;
  border-bottom: 1px solid #ebedf0;
  &.nobb {
    border-bottom: none;
  }
  label {
    width: 5.066667rem /* 95/18.75 */;
  }
  .content {
    flex: 1;
  }
}
.imgList {
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  padding: 0.266667rem /* 5/18.75 */ 0 0.8rem /* 15/18.75 */ 0 /* 20/18.75 */;
  .img-item {
    width: 2.666667rem /* 50/18.75 */;
    height: 2.666667rem /* 50/18.75 */;
    margin-right: 1.173333rem /* 22/18.75 */;
    border-radius: 0.266667rem /* 5/18.75 */;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.button {
  font-size: 0.8rem /* 15/18.75 */;
  font-family: PingFang SC;
  font-weight: 400;
  color: #2b71fd;
}
.van-checkbox-group {
  padding: 0.64rem /* 12/18.75 */ 1.066667rem /* 20/18.75 */;
  min-height: 16rem /* 300/18.75 */;
}
.my {
  display: flex;
  line-height: 1.28rem /* 24/18.75 */;
  &.my-popover {
    .van-popover__wrapper {
      width: 100%;
      input {
        width: 100% !important;
        text-align: left;
      }
    }
    /deep/.van-popup {
      transform: translate(-1.066667rem /* 20/18.75 */);
    }
    /deep/.van-popover__action {
      width: 10.666667rem /* 200/18.75 */;
    }
  }
  input {
    border: none;
    line-height: 1.28rem /* 24/18.75 */;
    color: @fcb;
    text-align: center;
    width: 2.4rem /* 45/18.75 */;
  }
}
.splx {
  width: 17.6rem /* 330/18.75 */;
  background: #ffffff;
  border-radius: 0.266667rem /* 5/18.75 */;
  .header {
    line-height: 2.88rem /* 54/18.75 */;
    font-size: 0.96rem /* 18/18.75 */;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    padding: 0.64rem /* 12/18.75 */;
    .tag-box {
      width: 33.3%;
      .df;
      justify-content: center;
      margin: 0.32rem /* 6/18.75 */ 0;
    }
    .tag {
      width: 4.533333rem /* 85/18.75 */;
      height: 1.493333rem /* 28/18.75 */;
      line-height: 1.386667rem /* 26/18.75 */;
      background: #ffffff;
      border: 0.053333rem /* 1/18.75 */ solid #888888;
      border-radius: 0.693333rem /* 13/18.75 */;
      font-size: 0.693333rem /* 13/18.75 */;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #969696;
      text-align: center;
      &.active {
        border: 0.053333rem /* 1/18.75 */ solid #1f7fff;
        color: #1f7fff;
      }
    }
  }
  .footer {
    font-size: 0.8rem /* 15/18.75 */;
    display: flex;
    align-items: center;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    .button {
      flex: 1;
      line-height: 2.88rem /* 54/18.75 */;
      font-family: PingFang SC;
      font-weight: 400;
      &.cancle {
        color: #999999;
      }
      &.confirm {
        color: #1f7fff;
      }
    }
    .line {
      width: 1px;
      height: 1.813333rem /* 34/18.75 */;
      background: #000000;
      opacity: 0.04;
    }
  }
}
.sjlx-box {
  .sjlx-item {
    .df;
    height: 4.266667rem /* 80/18.75 */;
    border-top: 0.053333rem /* 1/18.75 */ solid #f6f6fa;
    .img-box {
      width: 3.093333rem /* 58/18.75 */;
      height: 2.933333rem /* 55/18.75 */;
      border-radius: 0.266667rem /* 5/18.75 */;
      img {
        width: 100%;
      }
    }
    .name {
      flex: 1;
      font-size: 0.64rem /* 12/18.75 */;
      font-family: Source Han Sans CN;
      font-weight: 400;
      padding-left: 0.533333rem /* 10/18.75 */;
      color: #333333;
    }
    .del {
      width: 0.853333rem /* 16/18.75 */;
      height: 0.8rem /* 15/18.75 */;
    }
  }
}
</style>
